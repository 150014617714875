import { create } from 'zustand';

export enum RefundSteps {
  search = 'search',
  process = 'process',
  verifySms = 'verifySms',
}

type RefundInfoType = {
  clientName?: string;
  mobilePhone?: string;
  fullAmount?: string;
  choosenAmount?: string;
  orderId?: string;
};

interface RefundState {
  info: RefundInfoType;
  amount: string;
  currentStep: RefundSteps;
  setInfo: (info: RefundInfoType) => void;
  setAmount: (amount: string) => void;
  setCurrentStep: (step: RefundSteps) => void;
  reset: () => void;
}

const initialState = { amount: '', info: {}, currentStep: RefundSteps.search };

export const useRefundStore = create<RefundState>((set) => ({
  amount: '',
  info: {},
  currentStep: RefundSteps.search,
  setInfo: (info: RefundInfoType) =>
    set((state) => ({ ...state, info: { ...state.info, ...info } })),
  setAmount: (amount: string) => set((state) => ({ ...state, amount })),
  setCurrentStep: (step: RefundSteps) => set((state) => ({ ...state, currentStep: step })),
  reset: () => {
    set(initialState);
  },
}));
